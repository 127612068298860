/* import axios from "axios"; */
/* import { JUNKER_API_KEY } from "../../../config/junker.js"; */

import { database } from "../../../config/firebaseConfig";
import moment from "moment";

import {
  ref,
  query,
  orderByChild,
  startAt,
  //endAt,
  ref as databaseRef,
  child,
  onValue,
  set,
  get,
  push,
  update,
  remove /*    */,
} from "firebase/database";

//const token = context.rootState.auth.token;

const state = {
  visibleRange: {
    start: null,
    end: null,
  },
  wohnungen: [],
  belegungen: [],
  belegungsstatus: [],
  loading: false,
};

const mutations = {
  SET_VISIBLE_RANGE(state, { start, end }) {
    state.visibleRange.start = start;
    state.visibleRange.end = end;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_BELEGUNGEN(state, belegungen) {
    state.belegungen = belegungen;
  },
  UPDATE_BELEGUNG(state, updatedBelegung) {
    const index = state.belegungen.findIndex(
      (b) => b.id === updatedBelegung.id
    );

    if (index !== -1) {
      state.belegungen[index] = updatedBelegung;
    }
  },

  ADD_BELEGUNG(state, belegung) {
    const index = state.belegungen.findIndex((b) => b.id === belegung.id);
    if (index === -1) {
      state.belegungen.push(belegung);
    } else {
      // Optional: aktualisieren Sie die bestehende Belegung oder behandeln Sie den Fall
    }
  },
  REMOVE_BELEGUNG(state, payload) {
    // Neue Mutation zum Löschen eines Triggers
    const index = state.belegungen.findIndex(
      (belegung) => belegung.id === payload.id
    );
    if (index !== -1) {
      state.belegungen.splice(index, 1);
    }
  },

  SET_WOHNUNGEN(state, wohnungen) {
    state.wohnungen = wohnungen;
  },
  UPDATE_WOHNUNG(state, updatedWohnung) {
    const index = state.wohnungen.findIndex((b) => b.id === updatedWohnung.id);

    if (index !== -1) {
      state.wohnungen[index] = updatedWohnung;
    }
  },
  REMOVE_WOHNUNG(state, wohnungId) {
    const index = state.wohnungen.findIndex(
      (wohnung) => wohnung.id === wohnungId
    );
    if (index !== -1) {
      state.wohnungen.splice(index, 1);
    }
  },
  ADD_WOHNUNG(state, wohnung) {
    state.wohnungen.push(wohnung);
  },

  SET_CODE(state, { wohnungId, code }) {
    const index = state.wohnungen.findIndex((b) => b.id === wohnungId);
    if (index !== -1) {
      state.wohnungen[index].code = code;
    }
  },

  SET_BELEGUNGSSTATUS(state, status) {
    state.belegungsstatus = status;
  },
  ADD_BELEGUNGSSTATUS(state, status) {
    state.belegungsstatus.push(status);
  },
  UPDATE_BELEGUNGSSTATUS(state, updatedStatus) {
    const index = state.belegungsstatus.findIndex(
      (s) => s.id === updatedStatus.id
    );
    if (index !== -1) {
      state.belegungsstatus[index] = updatedStatus;
    }
  },
  REMOVE_BELEGUNGSSTATUS(state, statusId) {
    const index = state.belegungsstatus.findIndex((s) => s.id === statusId);
    if (index !== -1) {
      state.belegungsstatus.splice(index, 1);
    }
  },
};
const actions = {
  fetchWohnungen(context) {
    context.commit("SET_LOADING", true);
    const wohnungenRef = ref(database, "wohnungen");
    onValue(
      wohnungenRef,
      (snapshot) => {
        const data = snapshot.val();
        let wohnungenDO = [];

        for (const id in data) {
          wohnungenDO.push({ id, ...data[id] });
        }

        // Angepasste Sortierfunktion
        wohnungenDO = wohnungenDO
          .sort((a, b) => {
            const wohnungsartOrder = ["eigene", "fremd", "ferien"];
            const orderA = wohnungsartOrder.indexOf(a.wohnungsart);
            const orderB = wohnungsartOrder.indexOf(b.wohnungsart);

            if (orderA !== orderB) {
              return orderA - orderB;
            }

            // Bei gleicher 'wohnungsart', nach 'wohnung' alphabetisch sortieren
            return a.wohnung
              .toLowerCase()
              .localeCompare(b.wohnung.toLowerCase());
          })
          .map((wohnung, index) => ({ ...wohnung, nummer: index + 1 }));

        context.commit("SET_WOHNUNGEN", wohnungenDO);
        context.commit("SET_LOADING", false);
        console.log("WOHNUNGEN", wohnungenDO);
      },
      {
        onlyOnce: true,
      }
    );
  },

  setupWohnungenListener(context) {
    context.commit("SET_LOADING", true);

    const wohnungenRef = ref(database, "wohnungen");
    onValue(wohnungenRef, (snapshot) => {
      const data = snapshot.val();
      let wohnungenDO = [];

      for (const id in data) {
        wohnungenDO.push({ id, ...data[id] });
      }

      // Angepasste Sortierfunktion
      wohnungenDO = wohnungenDO
        .sort((a, b) => {
          const wohnungsartOrder = ["eigene", "fremd", "ferien"];
          const orderA = wohnungsartOrder.indexOf(a.wohnungsart);
          const orderB = wohnungsartOrder.indexOf(b.wohnungsart);

          if (orderA !== orderB) {
            return orderA - orderB;
          }

          // Bei gleicher 'wohnungsart', nach 'wohnung' alphabetisch sortieren
          return a.wohnung.toLowerCase().localeCompare(b.wohnung.toLowerCase());
        })
        .map((wohnung, index) => ({ ...wohnung, nummer: index + 1 }));
      console.warn("LISTENER WO");
      context.commit("SET_WOHNUNGEN", wohnungenDO);
      context.commit("SET_LOADING", false);
    });
  },

  async updateWohnung(context, payload) {
    function removeUndefinedProperties(obj) {
      return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] !== undefined) {
          acc[key] = obj[key];
        }
        return acc;
      }, {});
    }

    try {
      const wohnungRef = ref(database, `wohnungen/${payload.id}`);
      const updatedData = removeUndefinedProperties({ ...payload });

      // Wenn ein neuer Code vorhanden ist, behandeln Sie den alten Code und das Wechseldatum
      if (updatedData.code) {
        const currentWohnungSnapshot = await get(wohnungRef);
        const currentWohnungData = currentWohnungSnapshot.val();

        // Prüfen, ob bereits ein "code" Objekt existiert

        if (!currentWohnungData.code) {
          updatedData.code = {
            newCode: payload.code.newCode,
            oldCode: "0000", // Kein alter Code vorhanden
            activeCode: "0000",
            changeDate: new Date().toISOString(), // Kein Wechseldatum, da dies der erste Code ist
          };
        } else if (currentWohnungData.code?.newCode != payload.code.newCode) {
          if (currentWohnungData.code && currentWohnungData.code.activeCode) {
            updatedData.code.oldCode = currentWohnungData.code.activeCode;
          }
          if (currentWohnungData.code && currentWohnungData.code.newCode) {
            updatedData.code.activeCode = currentWohnungData.code.newCode;
          }
          if (payload.code.newCode) {
            updatedData.code.newCode = payload.code.newCode;
          }

          updatedData.code.changeDate = new Date().toISOString();

          /* if (
            currentWohnungData.code &&
            currentWohnungData.code.newCode &&
            currentWohnungData.code.activeCode
          ) {
            updatedData.code = {
              oldCode: currentWohnungData.code.activeCode,
              activeCode: currentWohnungData.code.newCode,
              changeDate: new Date().toISOString(), // ISO-Format für das Wechseldatum
              newCode: payload.code.newCode, // Setzen des neuen Codes als aktiven Code
            };
          } else {
            // Wenn es noch keinen "code" Bereich gibt, initialisieren Sie diesen entsprechend
            updatedData.code = {
              newCode: payload.code.newCode,
              oldCode: null, // Kein alter Code vorhanden
              changeDate: null, // Kein Wechseldatum, da dies der erste Code ist
            };
          } */
        }
      }

      delete updatedData.id; // Entfernen Sie die ID, da sie nicht aktualisiert werden soll

      await update(wohnungRef, updatedData);

      context.commit("UPDATE_WOHNUNG", {
        ...payload,
        code: updatedData.code, // Stellen Sie sicher, dass der gesamte "code" Bereich übergeben wird
      });
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Wohnung:", error);
    }
  },

  async createWohnung(context, wohnung) {
    try {
      const wohnungenRef = ref(database, "wohnungen");
      const newWohnungRef = push(wohnungenRef);
      await set(newWohnungRef, wohnung);

      context.commit("ADD_WOHNUNG", { ...wohnung, id: newWohnungRef.key });
      console.log("Wohnung Store", wohnung, newWohnungRef.key);
    } catch (error) {
      console.error("Fehler beim Erstellen der Wohnung:", error);
    }
  },

  async storeWohnung(context, payload) {
    try {
      const wohnungRef = ref(
        database,
        `wohnungen/${payload.id ? payload.id : ""}`
      );
      if (payload.id) {
        await update(wohnungRef, payload);
      } else {
        const newWohnungRef = push(wohnungRef);
        await set(newWohnungRef, payload);
        payload.id = newWohnungRef.key;
      }

      context.commit(payload.id ? "UPDATE_WOHNUNG" : "ADD_WOHNUNG", payload);
    } catch (error) {
      console.error("Fehler beim Speichern der Wohnung:", error);
    }
  },

  async deleteWohnung(context, wohnungId) {
    try {
      const wohnungRef = ref(database, `wohnungen/${wohnungId}`);
      await remove(wohnungRef);

      context.commit("REMOVE_WOHNUNG", wohnungId);
    } catch (error) {
      console.error("Fehler beim Löschen der Wohnung:", error);
    }
  },

  fetchBelegungen(context) {
    //context.commit("SET_LOADING", true);
    const belegungenRef = ref(database, "belegungen");

    onValue(
      belegungenRef,
      (snapshot) => {
        const data = snapshot.val();
        const belegungenDO = [];

        for (const id in data) {
          belegungenDO.push({ id, ...data[id] });
        }

        /* console.log("BelegungenDO", belegungenDO); */
        context.commit("SET_BELEGUNGEN", belegungenDO);
        context.commit("SET_LOADING", false);
      },
      {
        onlyOnce: true,
      }
    );
  },

  async fetchBelegungenLazy({ commit, state }) {
    //commit("SET_LOADING", true);

    const { start, end } = state.visibleRange;

    const startDate =
      start instanceof Date ? start.toISOString().split("T")[0] : start;
    //const endDate = end instanceof Date ? end.toISOString().split('T')[0] : end;

    console.log(`Daten laden für Bereich von ${start} bis ${end}`);
    /*  const startTimestamp = moment(start);
      const endTimestamp = moment(end); */

    if (start && end) {
      const belegungenRef = query(
        ref(database, "belegungen"),
        orderByChild("enddatum"),
        startAt(startDate)
        //endAt(end)
      );

      onValue(belegungenRef, (snapshot) => {
        const data = snapshot.val();
        const belegungen = [];
        for (const id in data) {
          belegungen.push({ id, ...data[id] });
        }
        commit("SET_BELEGUNGEN", belegungen);
        commit("SET_LOADING", false);
      });
    }
  },

  setupBelegungenListener(context) {
    //context.commit("SET_LOADING", true);
    context.commit("SET_LOADING", false);
    const belegungenRef = ref(database, "belegungen");
    onValue(belegungenRef, (snapshot) => {
      const data = snapshot.val();
      const belegungenDO = [];

      for (const id in data) {
        belegungenDO.push({ id, ...data[id] });
      }
      console.warn("LISTENER");
      context.commit("SET_BELEGUNGEN", belegungenDO);
      context.commit("SET_LOADING", false);
    });
  },

  async updateBelegung(context, payload) {
    /* function removeUndefinedProperties(obj) {
      return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] !== undefined) {
          acc[key] = obj[key];
        }
        return acc;
      }, {});
    } */
    function removeUndefinedProperties(obj) {
      return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] === undefined) {
          // Nichts tun, wenn der Wert undefined ist
        } else if (
          obj[key] !== null &&
          typeof obj[key] === "object" &&
          !Array.isArray(obj[key])
        ) {
          // Rekursiver Aufruf, wenn es sich um ein Objekt handelt (aber nicht um ein Array)
          acc[key] = removeUndefinedProperties(obj[key]);
        } else {
          // Direktes Kopieren des Werts, wenn er nicht undefined ist und kein verschachteltes Objekt
          acc[key] = obj[key];
        }
        return acc;
      }, {});
    }

    try {
      const belegungRef = ref(database, `belegungen/${payload.id}`);
      console.log("BEFOREREMOVE", payload);

      // Bereiten Sie das Update-Objekt vor. Stellen Sie sicher, dass es keine 'undefined' Werte enthält.
      const updatedData = removeUndefinedProperties({ ...payload });
      delete updatedData.id; // Entfernen Sie die ID, da sie nicht aktualisiert werden soll

      // Lesen Sie die bestehende Belegung, um vorhandene Daten zu erhalten
      const snapshot = await get(belegungRef);
      const existingData = snapshot.exists() ? snapshot.val() : {};

      // Bewahren Sie vorhandene Daten im lexoffice-Schlüssel auf und fügen Sie neue Informationen hinzu
      updatedData.lexoffice = {
        ...existingData.lexoffice,
        ...updatedData.lexoffice,
      };

      console.log("AFTERREMOVE", updatedData);

      await update(belegungRef, updatedData);

      // Aktualisieren Sie den Vuex-Store
      await context.dispatch("fetchBelegungen"); // Belegungsliste aktualisieren
      context.commit("SET_LOADING", false);
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Belegung:", error);
    }

    /* try {
      const belegungRef = ref(database, `belegungen/${payload.id}`);
      console.log("BEFOREREMOVE", payload);
      // Bereiten Sie das Update-Objekt vor. Stellen Sie sicher, dass es keine 'undefined' Werte enthält.
      const updatedData = removeUndefinedProperties({ ...payload });
      delete updatedData.id; // Entfernen Sie die ID, da sie nicht aktualisiert werden soll
      console.log("AFTERREMOVE", updatedData);

      await update(belegungRef, updatedData);

      // Aktualisieren Sie den Vuex-Store
      //context.commit("UPDATE_BELEGUNG", payload);
      //context.commit("UPDATE_BELEGUNG", payload);
      await context.dispatch("fetchBelegungen"); // Belegungsliste aktualisieren
      context.commit("SET_LOADING", false);
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Belegung:", error);
    } */
  },


  async updateBelegungWithWohnungIds() {
    try {
      // Erstelle eine Map von Wohnungsnamen und IDs
      const wohnungenRef = ref(database, "wohnungen");
      const wohnungenSnapshot = await get(wohnungenRef);
      const wohnungen = wohnungenSnapshot.val();
      if (!wohnungen) throw new Error("Keine Wohnungen gefunden");
  
      const wohnungMap = new Map();
      for (const [id, wohnung] of Object.entries(wohnungen)) {
        wohnungMap.set(wohnung.wohnung, id);
      }
  
      // Hole alle Belegungen
      const belegungenRef = ref(database, "belegungen");
      const belegungenSnapshot = await get(belegungenRef);
      const belegungen = belegungenSnapshot.val();
      if (!belegungen) throw new Error("Keine Belegungen gefunden");
  
      // Aktualisiere jede Belegung mit der entsprechenden Wohnungs-ID
      for (const [id, belegung] of Object.entries(belegungen)) {
        const wohnungId = wohnungMap.get(belegung.wohnung);
        if (wohnungId) {
          belegung.wohnungid = wohnungId;
          await update(ref(database, `belegungen/${id}`), belegung);
          console.log("Belegung aktualisiert:", belegung.wohnung, " > ", wohnungId);
        } else {
          console.log(`Wohnung nicht gefunden für Belegung: ${id}`);
        }
      }
  
      console.log("Belegungen erfolgreich aktualisiert");
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Belegungen:", error);
    }
  },


  async updateSequenceStartItemIds() {
    try {
      const belegungenRef = ref(database, "belegungen");
      const belegungenSnapshot = await get(belegungenRef);
      const belegungen = belegungenSnapshot.val();
      if (!belegungen) throw new Error("Keine Belegungen gefunden");

      // Gruppiere Belegungen nach wohnung und firma
      const groupedBelegungen = {};
      for (const [id, belegung] of Object.entries(belegungen)) {
        const key = `${belegung.wohnung}-${belegung.firma}`;
        if (!groupedBelegungen[key]) {
          groupedBelegungen[key] = [];
        }
        groupedBelegungen[key].push({ id, ...belegung });
      }

      // Verarbeite jede Gruppe
      for (const [key, belegungenGroup] of Object.entries(groupedBelegungen)) {
        // Sortiere die Belegungen nach startdatum
        belegungenGroup.sort(
          (a, b) => new Date(a.startdatum) - new Date(b.startdatum)
        );

        let startItemId = null;
        let previousEndDate = null;
        let originalStartDate = null;

        console.log("belegungenGroup", key, belegungenGroup);

        for (const belegung of belegungenGroup) {
          if (
            previousEndDate &&
            new Date(previousEndDate).toISOString().split("T")[0] ===
              belegung.startdatum
          ) {
            // Fortsetzung der Sequenz
            if (!belegung.sequence) {
              belegung.sequence = {};
            }
            belegung.sequence.startItemId = startItemId;
            belegung.startdatum_original = originalStartDate;
          } else {
            // Neue Sequenz
            if (!belegung.sequence) {
              belegung.sequence = {};
            }
            startItemId = belegung.id;
            belegung.sequence.startItemId = startItemId;
            originalStartDate = belegung.startdatum;
            belegung.startdatum_original = originalStartDate;
          }

          // Aktualisiere die Belegung in der Datenbank
          await update(ref(database, `belegungen/${belegung.id}`), belegung);

          // Setze das Enddatum für die nächste Iteration
          previousEndDate = belegung.enddatum;
        }
      }
    } catch (error) {
      console.error(
        "Fehler beim Aktualisieren der Sequenz-Start-Item-IDs:",
        error
      );
    }
  },

  /*   async updateSequenceStartItemIds() {
    try {
      const belegungenRef = ref(database, "belegungen");
      const belegungenSnapshot = await get(belegungenRef);
      const belegungen = belegungenSnapshot.val();

      if (!belegungen) throw new Error("Keine Belegungen gefunden");

      const nextItemMap = {};
      const startItems = new Set(Object.keys(belegungen));

      // Erstelle eine Map, um die Objekte nach ihrer nextItemId zu gruppieren
      for (const [id, belegung] of Object.entries(belegungen)) {
        if (belegung.sequence && belegung.sequence.isSequenceItem) {
          if (belegung.sequence.nextItemId) {
            nextItemMap[belegung.sequence.nextItemId] = id;
            startItems.delete(belegung.sequence.nextItemId);
          }
        }
        if (belegung.sequence && !belegung.sequence.isSequenceItem) {
          startItems.delete(id);
        }
      }

      console.log("nextItemMap", nextItemMap);
      console.log("startItems", startItems);


      for (const startItemId of startItems) {
        let currentItemId = startItemId;
        const startItem = belegungen[startItemId];
        const startdatumOriginal = startItem.startdatum; // Speichere das startdatum des startItems
        console.log("currentItemId", currentItemId);
        while (currentItemId) {
          const currentItem = belegungen[currentItemId];
      
          if (currentItem.sequence) {
            currentItem.sequence.startItemId = startItemId;
            currentItem.startdatum_original = startdatumOriginal; // Füge startdatum_original hinzu
      
            await update(
              ref(database, `belegungen/${currentItemId}`),
              currentItem
            );
      
            if (belegungen[currentItem.sequence.nextItemId]) {
              currentItemId = currentItem.sequence.nextItemId;
            } else {
              currentItemId = null;
            }
          } else {
            currentItemId = null;
          }
        }
      }
      // Aktualisiere die startItemId für jede Sequenz
      for (const startItemId of startItems) {
        let currentItemId = startItemId;
        console.log("currentItemId", currentItemId);
        while (currentItemId) {
          const currentItem = belegungen[currentItemId];

          if (currentItem.sequence) {
            currentItem.sequence.startItemId = startItemId;

            await update(
              ref(database, `belegungen/${currentItemId}`),
              currentItem
            );

            if (belegungen[currentItem.sequence.nextItemId]) {
              currentItemId = currentItem.sequence.nextItemId;
            } else {
              currentItemId = null;
            }
          } else {
            currentItemId = null;
          }
        }
      }

      console.log("startItemId für alle Sequenzen aktualisiert");
    } catch (error) {
      console.error("Fehler beim Aktualisieren der startItemId:", error);
    }
  }, */

  async createBelegung(context, payload) {
    // Definieren Sie die Funktion innerhalb der Action
    /* function removeUndefinedProperties(obj) {
      return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] !== undefined) {
          acc[key] = obj[key];
        }
        return acc;
      }, {});
    } */
    function removeUndefinedProperties(obj) {
      return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] === undefined) {
          // Nichts tun, wenn der Wert undefined ist
        } else if (
          obj[key] !== null &&
          typeof obj[key] === "object" &&
          !Array.isArray(obj[key])
        ) {
          // Rekursiver Aufruf, wenn es sich um ein Objekt handelt (aber nicht um ein Array)
          acc[key] = removeUndefinedProperties(obj[key]);
        } else {
          // Direktes Kopieren des Werts, wenn er nicht undefined ist und kein verschachteltes Objekt
          acc[key] = obj[key];
        }
        return acc;
      }, {});
    }

    try {
      // Entfernen Sie alle undefined-Eigenschaften
      const belegungsItem = removeUndefinedProperties({ ...payload });

      const belegungenRef = ref(database, "belegungen");
      const newBelegungRef = push(belegungenRef);

      await set(newBelegungRef, {
        ...belegungsItem,
        sequence: {
          startItemId: newBelegungRef.key,
        },
      });
      console.log("newBelegungRef", newBelegungRef.key);

      context.commit("ADD_BELEGUNG", {
        ...belegungsItem,
        id: newBelegungRef.key,
        sequence: {
          startItemId: newBelegungRef.key,
        },
      });

      return newBelegungRef.key; // Rückgabe der ID der neu erstellten Belegung
    } catch (error) {
      console.error("Fehler beim Erstellen der Belegung:", error);
    }
  },

  async deleteBelegung(context, payload) {
    try {
      const belegungRef = ref(database, `belegungen/${payload.id}`);
      await remove(belegungRef);

      context.commit("REMOVE_BELEGUNG", payload);
    } catch (error) {
      console.error("Fehler beim Löschen der Belegung:", error);
    }
  },

  async splitBelegung(context, { belegungId, splitDate }) {
    try {
      const belegungenRef = ref(database, `belegungen/${belegungId}`);
      const belegungSnapshot = await get(belegungenRef);
      const belegung = belegungSnapshot.val();

      if (!belegung) throw new Error("Belegung nicht gefunden");

      const startDate = new Date(belegung.startdatum);
      const endDate = new Date(belegung.enddatum);
      const splitDateTime = new Date(splitDate);

      if (splitDateTime >= startDate && splitDateTime < endDate) {
        const formatDate = (date) => moment(date).format("YYYY-MM-DD");

        // Generiere eine neue Belegungs-ID
        const newBelegungRef = push(ref(database, "belegungen"));
        const newBelegungId = newBelegungRef.key;

        let startItemId = "";
        if (belegung.sequence && belegung.sequence.startItemId) {
          startItemId = belegung.sequence.startItemId;
        } else {
          startItemId = belegungId;
        }

        // Aktualisieren Sie das Enddatum der aktuellen Belegung
        const updatedBelegung = {
          ...belegung,
          enddatum: formatDate(splitDateTime),
          sequence: {
            ...belegung.sequence,
            isSequenceItem: true,
            isSequenceLastItem: false,
            nextItemId: newBelegungId,
          },
        };

        await update(belegungenRef, updatedBelegung);

        // Erstellen Sie eine neue Belegung für den verbleibenden Zeitraum
        const newBelegung = {
          ...belegung,
          startdatum: formatDate(splitDateTime),

          sequence: {
            ...belegung.sequence,
            isSequenceItem: true,
            isSequenceLastItem: true,
            prevItemId: belegungId,
            startItemId: startItemId,
          },
        };
        //const newBelegungRef = push(ref(database, "belegungen"));
        await set(newBelegungRef, newBelegung);

        //await context.dispatch("fetchBelegungen"); // Belegungsliste aktualisieren
      }
    } catch (error) {
      console.error("Fehler beim Aufteilen der Belegung:", error);
    }
  },

  async saveChanges({ dispatch }, { item, selectedTriggers }) {
    if (item.id !== null) {
      await dispatch("updateBelegung", {
        id: item.id,
        firma: item.firma,
        kundennr: item.kundennr,
        wohnung: item.wohnung,
        wohnungid: item.wohnungid,

        startdatum: item.startdatum,
        enddatum: item.enddatum,
        anzahl: item.anzahl,
        status: item.status,
        contact: item.contact,
        strasse: item.strasse,
        plz: item.plz,
        ort: item.ort,
        ansprechpartner: item.ansprechpartner,
        email: item.email,
        abgerechnet_am: item.abgerechnet_am,
        nettoek: item.nettoek,
        nettovk: item.nettovk,
        mwst: item.mwst,
        abrechnungsart: item.abrechnungsart,
        unbefristet: item.unbefristet,
        reinigung: item.reinigung,
        reinigungskosten: item.reinigungskosten,
        sequence: {
          isSequenceItem: item.isSequenceItem,
          startItemId: item.startItemId,
        },
        code: {
          activeCode: item.code.activeCode,
          newCode: item.code.newCode,
        },
      });

      const belegungId = item.id;
      for (const trigger of selectedTriggers) {
        await dispatch("executeTrigger", {
          triggerId: trigger.id,
          belegungId: belegungId,
        });
      }
    } else {
      try {
        const belegungId = await dispatch("createBelegung", {
          firma: item.firma,
          status: item.status,
          kundennr: item.kundennr,
          wohnung: item.wohnung,
          wohnungid: item.wohnungid,

          startdatum: item.startdatum,
          startdatum_original: item.startdatum,
          enddatum: item.enddatum,
          anzahl: item.anzahl,
          contact: item.contact,
          strasse: item.strasse,
          plz: item.plz,
          ort: item.ort,
          ansprechpartner: item.ansprechpartner,
          email: item.email,
          nettoek: item.nettoek,
          nettovk: item.nettovk,
          mwst: item.mwst,
          unbefristet: item.unbefristet,
          reinigung: item.reinigung,
          reinigungskosten: item.reinigungskosten,
          sequence: {
            isSequenceItem: false,
          },
          code: {
            activeCode: item.code.activeCode,
            newCode: item.code.newCode,
          },
          abrechnungsart: item.abrechnungsart,
        });

        for (const trigger of selectedTriggers) {
          await dispatch("executeTrigger", {
            triggerId: trigger.id,
            belegungId: belegungId,
          });
        }
      } catch (error) {
        console.error("Fehler beim Erstellen der Belegung:", error);
      }
    }
  },

  ////// BELEGUNGSSTATUS

  fetchBelegungsStatus(context) {
    context.commit("SET_LOADING", true);
    const dbRef = databaseRef(database);
    get(child(dbRef, `belegungsstatus`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const statusList = [];
          const data = snapshot.val();
          for (const statusid in data) {
            statusList.push({
              id: statusid,
              ...data[statusid],
            });
          }
          context.commit("SET_BELEGUNGSSTATUS", statusList);
        } else {
          console.log("No data available");
        }
        context.commit("SET_LOADING", false);
      })
      .catch((error) => {
        console.error(error);
        context.commit("SET_LOADING", false);
      });
  },

  storeBelegungsStatus(context, payload) {
    context.commit("SET_LOADING", true);

    if (payload.id) {
      // Existierenden Belegungsstatus aktualisieren
      const updateRef = databaseRef(database, `belegungsstatus/${payload.id}`);
      const belegungsstatusToUpdate = { ...payload };
      delete belegungsstatusToUpdate.id; // ID nicht im Objekt speichern

      update(updateRef, belegungsstatusToUpdate)
        .then(() => {
          context.dispatch("fetchBelegungsStatus");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // Neuen Belegungsstatus hinzufügen
      const newStatusRef = databaseRef(database, "belegungsstatus");
      push(newStatusRef, payload)
        .then(() => {
          // Du könntest hier die ID des neuen Eintrags verwenden, z.B. dataRef.key
          context.dispatch("fetchBelegungsStatus");
        })
        .catch((error) => {
          console.error(error);
        });
    }
    context.commit("SET_LOADING", false);
  },

  deleteBelegungsStatus(context, payload) {
    context.commit("SET_LOADING", true);
    const dbRef = databaseRef(database, `belegungsstatus/${payload.id}`);
    remove(dbRef)
      .then(() => {
        context.commit("REMOVE_BELEGUNGSSTATUS", payload);
        context.dispatch("fetchBelegungsStatus"); // Je nach Bedarf
      })
      .catch((error) => {
        console.error(error);
      });
    context.commit("SET_LOADING", false);
  },

  /*   fetchBelegungsStatus(context) {
    context.commit("SET_LOADING", true);

    const token = context.rootState.auth.token;
    axios
      .get(
        `https://junker-auen-default-rtdb.europe-west1.firebasedatabase.app/belegungsstatus.json?auth=${token}`
      )
      .then((response) => {
        const statusList = [];

        for (const statusid in response.data) {
          statusList.push({
            id: statusid,

            ...response.data[statusid],
          });
        }
        context.commit("SET_BELEGUNGSSTATUS", statusList);
        context.commit("SET_LOADING", false);
      })
      .catch((error) => {
        console.log(error);
        context.commit("SET_LOADING", false);
      });
  }, */

  /*   storeBelegungsStatus(context, payload) {
    const token = context.rootState.auth.token;
    const belegungsstatusDO = { ...payload };

    console.log("PAYLOAD.ID", payload.id);
    // Check if adding a new status or updating an existing one
    if (payload.id) {
      delete belegungsstatusDO.id;

      axios
        .put(
          `https://junker-auen-default-rtdb.europe-west1.firebasedatabase.app/belegungsstatus/${payload.id}.json?auth=${token}`,
          belegungsstatusDO
        )
        .then(() => {
          context.dispatch("fetchBelegungsStatus");
          //context.commit("UPDATE_BELEGUNGSSTATUS", belegungsstatusDO);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(
          `https://junker-auen-default-rtdb.europe-west1.firebasedatabase.app/belegungsstatus.json?auth=${token}`,
          belegungsstatusDO
        )
        .then((response) => {
          //belegungsstatusDO.id = response.data.name;
          console.log("Status gespeichert:", response);
          //context.commit("ADD_BELEGUNGSSTATUS", belegungsstatusDO);
          context.dispatch("fetchBelegungsStatus");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, */

  /*   deleteBelegungsStatus(context, payload) {
    // Neue Action zum Löschen eines Triggers
    const token = context.rootState.auth.token;
    if (payload.id) {
      axios
        .delete(
          `https://junker-auen-default-rtdb.europe-west1.firebasedatabase.app/belegungsstatus/${payload.id}.json?auth=${token}`
        )
        .then(() => {
          //context.dispatch("fetchBelegungsStatus");
          context.commit("REMOVE_BELEGUNGSSTATUS", payload);
          context.dispatch("fetchBelegungen");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, */
};

const getters = {
  Wohnungen: (state) => {
    return state.wohnungen;
  },
  Belegungen: (state) => {
    return state.belegungen;
  },
  isLoading: (state) => {
    return state.loading;
  },
  Belegungsstatus: (state) => {
    return state.belegungsstatus;
  },

  getWohnungByValue: (state) => (wohnungsWert) => {
    return state.wohnungen.find((wohnung) => wohnung.wohnung === wohnungsWert);
  },
  getWohnungById: (state) => (id) => {
    return state.wohnungen.find((wohnung) => wohnung.id === id);
  },
  
  getBelegungById: (state) => (id) => {
    return state.belegungen.find((belegung) => belegung.id === id);
  },

  getBelegungenByIds: (state) => (ids) => {
    return ids
      .map((id) => state.belegungen.find((b) => b.id === id))
      .filter((b) => b != null);
  },
};

const belegungenModule = {
  state,
  mutations,
  actions,
  getters,
};

export default belegungenModule;
